exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2eOdf{to{-webkit-transform:rotate(1turn)}}@keyframes _2eOdf{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2Ol7l{background-color:#ebebeb;color:#043544;width:100%;padding:16px 96px;font-size:16px;line-height:1;font-weight:500}@media only screen and (max-width:599px){._2Ol7l{padding:16px 4px;font-size:12px}}._2Ol7l .eZ97X{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-align:center;align-items:center}._2Ol7l .eZ97X ._2svi-{padding:0 16px}._2Ol7l .eZ97X ._2tq5F{color:#043544;text-decoration:none}._2Ol7l .eZ97X ._1i043{color:#1c748a;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"subheader": "_2Ol7l",
	"subheader-content": "eZ97X",
	"item": "_2svi-",
	"selection": "_2tq5F",
	"selected": "_1i043",
	"spin": "_2eOdf"
};