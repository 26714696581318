import React, {lazy, Suspense, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import 'moment-timezone';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './batchAddEditCustomBrouchureSection.scss';
import GenericStyledModal from '../../../../forms/pages/admin/modals/GenericStyledModal.modal';
import { ThemeProvider } from 'styled-components';
import { themeViaForms } from '../../../../../styledComponents/settings/theme';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import { AccentButtonMedium, ButtonMedium } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import {Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _get from 'lodash/get';
import { 
        batchAddEditCustomBrochureSection, 
        batchAddEditCustomBrochureSectionClear,
        addEditCustomBrochureSection,
        addEditCustomBrochureSectionClear
      } from '../../../../../actions/programModifyActions';
import { getProgram } from '../../../../../actions/programBrochure';      
const EditTextAreaQuill = lazy(() => import('../../../../../styledComponents/styles/EditTextAreaQuill.styled'));

export const enhance = compose(withStyles(s,toastMessageStyles));

function BatchAddEditCustomBrouchureSection(props) {
  const dispatch = useDispatch();
  const customAliases = useSelector(state=>state.profile.customAliases);
  const batchProgramBrochureSection = useSelector(state=>state.programModify.batchProgramBrochureSection);
  const programBrochureSection = useSelector(state=>state.programModify.programBrochureSection);
  const [programCount, setProgramCount] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(()=>{
    if(props.selectedPrograms) {
      setProgramCount(Array.isArray(props.selectedPrograms) ? props.selectedPrograms.length : 1);
    }
  },[])

  useEffect(()=>{
    if(props.isBatchEdit) {
      setIsSaving(batchProgramBrochureSection?.batchLoading ? true : false);
      
      if(batchProgramBrochureSection?.batchError) {
        toast('An error occurred while adding the brochure section.', {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        });
      }    

      if(batchProgramBrochureSection?.batchSuccess && !batchProgramBrochureSection.batchLoading) {
        toast('Brochure section saved', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        }); 
        props.onClose(false);
      }
    }
  },[batchProgramBrochureSection])

  useEffect(()=>{
    if(!props.isBatchEdit) {
      setIsSaving(programBrochureSection?.loading ? true : false);
      
      if(programBrochureSection?.error) {
        toast('An error occurred while adding the brochure section.', {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        });
      }    

      if(programBrochureSection?.success && !programBrochureSection.loading) {
        dispatch(getProgram(props.selectedPrograms, 'client'));
        toast('Brochure section saved', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        }); 
        props.onClose(false);
      }
    }
  },[programBrochureSection])


  useEffect(()=>{
    if(!props.isBatchEdit) {
      const programBrochureSection = props.programBrochureSection;
      setValue("section_title", programBrochureSection.title);
      setValue("brochure_section", programBrochureSection.description);
    }
  },[])
  
  const schema = yup.object().shape({
    section_title: yup.string().required('Section title is required'),
    brochure_section: yup.string().required('Brochure section is required'),
  });

  const { control, handleSubmit, formState, getValues, register, setValue ,reset } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    //defaultValues,
  });

  const onSubmitHandler = (data) => {
    if(props.isBatchEdit) {
      const reqParam = {
        program_ids : props.selectedPrograms,
        program_brochure_section : {
          title : data?.section_title ? data?.section_title : "",
          description : data?.brochure_section ? data?.brochure_section : ""
        }
      }
      dispatch(batchAddEditCustomBrochureSection(reqParam));
      setTimeout(()=>{
        dispatch(batchAddEditCustomBrochureSectionClear());
      },2000)
    } else {
      const reqParam = {
        program_id : props.selectedPrograms,
        program_brochure_section : {
          title : data?.section_title ? data?.section_title : "",
          description : data?.brochure_section ? data?.brochure_section : ""
        }
      }
      dispatch(addEditCustomBrochureSection(reqParam));
      setTimeout(()=>{
        dispatch(addEditCustomBrochureSectionClear());
      },2000)
    }
  }

  return (
    <>
    <ThemeProvider theme={themeViaForms}>
      <GenericStyledModal
        header="Add/Edit Custom Brochure Section"
        showing={props.show}
        width="730px"
        paddingBottom="0px"
        onClose={() => {
          props.onClose(false);
        }}
      >
        <div className={s['modal-container']}>
          <form id="custom-brochure" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className={s.modalBody}>
              <div className={s.paragraph}>
                You are about to add/edit the custom brochure sections for <strong>{programCount} Authorized/Internal {customAliases?.alias_programs ? customAliases.alias_programs : "Programs"}</strong>. This information will be visible to {customAliases?.alias_traveler ? customAliases.alias_traveler : "traveler"}(s) on the {customAliases?.alias_program ? customAliases.alias_program : "program"} brochure.
              </div>
              <div className={`${s.formFieldContainer}`}>
                <Controller
                  control={control}
                  name={'section_title'}  
                  render={({ field: { onChange,  value, name, ref} }) => (
                      <TextInput
                        errorMsg={_get(formState.errors.section_title, 'message') || ''}
                        id={"section_title"}
                        tooltip={"This title will serve as the section header for your custom section on the program brochure."}
                        isTooltipArrow={true}
                        aria-label="Brochure Section Title"
                        name={"section_title"}
                        openSans
                        label={`Brochure Section Title`}
                        placeholder={'Enter section title'}
                        required
                        charLimit={70}
                        control={control}
                        errors={formState.errors}
                        isDirty={formState.isDirty}
                        value={value}
                        getValues={getValues}
                        register={register}
                        onChange={(value) => { 
                            setValue("section_title", value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            onChange(value);
                          }
                        }
                        inputBorderRadius="4px"

                      />
                  )}
                />
              </div>
              <div className={`${s.formFieldContainer}`}>
                <Suspense fallback={<span />}>
                <Controller
                  control={control}
                  name={'brochure_section'}
                  render={({ field: { onChange,  value, name, ref} }) => (
                    <EditTextAreaQuill
                      aria-label={"Brochure Section"}
                      id="brochure_section"
                      label="Brochure Section"
                      errorMsg={_get(formState.errors.brochure_section, 'message') || ''}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      openSans
                      required
                      value={value}
                      placeholder={`Enter brochure section...`}
                      onChange={(value)=> {
                        setValue("brochure_section",value, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        })
                      }}
                    />
                  )}
                />
                </Suspense>
              </div>
            </div>      
          
            <div className={s.buttonWrapper}>
              <div className={s.buttonItem}>
                <ButtonMedium 
                  style={{fontWeight:'700'}}
                  type="button" 
                  aria-label="Cancel" 
                  disabled={isSaving} 
                  onClick={() => props.onClose(false)}>
                  Cancel
                </ButtonMedium>
              </div>
              <div className={`${s.buttonItem} ${s.buttonSave}`}>
                <AccentButtonMedium
                  style={{fontWeight:'700'}}
                  aria-label={`Add/Edit`}
                  disabled={isSaving}
                  saving={isSaving}
                  type="submit"
                >
                  {isSaving ? <AccentButtonSpinner displayText='Loading...' /> : 'Add/Edit'}
                </AccentButtonMedium>
              </div>
            </div>
          </form>
        </div>
      </GenericStyledModal>
    </ThemeProvider>  
    </>
  );
}

BatchAddEditCustomBrouchureSection.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddEditCustomBrouchureSection);
