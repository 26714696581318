import fetch from 'node-fetch';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import {
  FETCH_GRAPH_INFORMATION,
  FETCH_GRAPH_PROGRAM,
  FETCH_POPULAR_PROGRAMS,
  FETCH_RECENT_CONNECTS,
  FETCH_ANALYTICS_REPORT,
  FETCH_ORGANIZATION_INSIGHTS,
  FETCH_ORGANIZATION_INFORMATION,
} from './types';
import { Cookies } from 'react-cookie';

export function fetchGraphInformation() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/graph_information`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_GRAPH_INFORMATION,
          payload: data,
        }),
      );
  };
}

export function fetchProgramGraphInformation() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/program_authorized_graph`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_GRAPH_PROGRAM,
          payload: data,
        }),
      );
  };
}

export function fetchOrganizationInsights() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/organization_insights`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ORGANIZATION_INSIGHTS,
          payload: data,
        }),
      );
  };
}

export function fetchOrganizationInformation(id) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/organization_info?org_id=${id}`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ORGANIZATION_INFORMATION,
          payload: data,
        }),
      );
  };
}

export function fetchPopularPrograms() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/popular_programs`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_POPULAR_PROGRAMS,
          payload: data,
        }),
      );
  };
}

export function fetchRecentConnects() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/recent_connects`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_RECENT_CONNECTS,
          payload: data,
        }),
      );
  };
}

export function fetchAnalyticsReports() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/reporting_connects/connects/`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ANALYTICS_REPORT,
          payload: data,
        }),
      );
  };
}
